/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


.app {
    width: 100%;
    min-height: 100vh;
    background-color: #f6f7f9
}

.form-control {
    font-size: 0.9rem !important;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #605c5c !important;
}

::-webkit-scrollbar-thumb {
    background: grey !important;
}

::-webkit-scrollbar:vertical {
    width: 7px !important;
    height: 7px;
    // display: none;
}

::-webkit-scrollbar:horizontal {
    width: 7px !important;
    height: 7px;
}

:hover::-webkit-scrollbar:vertical {
    // width: 5px !important;
    // height: 11px;
    display: flex;
}

:hover::-webkit-scrollbar:horizontal {
    // width: 11px !important;
    // height: 11px;
}

.app-main {
    position: relative;
    padding-top: 0rem;
    padding-right: 0;
    padding-left: 0;
    overflow: hidden
}

.page-section {
    padding-right: 0;
    padding-left: 0
}

.page-content {
    height: calc(100vh - 50px);
    overflow-y: auto;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px
}

.main-color {
    color: #4BADFF;
}

.ng-select.is-invalid .ng-select-container {
    border-color: #dc1523 !important;
}

.ng-select .ng-select-container {
    min-height: calc(2.10rem + 2px) !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    background: #DC1523 !important;
    color: #f6f7f9 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    background: #DC1523 !important;
    color: #f6f7f9 !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    background-color: #fff !important;
    border-color: #346cb0 !important;
    outline: 0 !important;
    box-shadow: 0 0 0 1px #346cb0 !important;
}

.modal.modal-alert .modal-dialog {
    opacity: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    transition: opacity .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out, opacity .15s ease-in-out;
    transition: transform .15s ease-in-out, opacity .15s ease-in-out, -webkit-transform .15s ease-in-out
}

.modal.modal-alert.show .modal-dialog {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
}

.modal-dialog-centered {
    margin: 0 auto
}

.modal-content {
    margin: 0 12%
}

.modal-footer,
.modal-header {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.modal-body {
    padding-top: 2px;
    padding-bottom: 2px
}

.modal-dialog-overflow .modal-content {
    max-height: calc(100vh - 1rem)
}

@media (min-width:576px) {
    .modal-dialog-overflow .modal-content {
        max-height: calc(100vh - 3.5rem)
    }
}

.modal-dialog-overflow .modal-body {
    overflow-y: auto
}

.modal-dialog-overflow .modal-footer,
.modal-dialog-overflow .modal-header {
    z-index: 2
}

.modal-drawer .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
    position: fixed;
    top: 0;
    bottom: 0
}

@media (max-width:575.98px) {
    .modal-drawer .modal-dialog {
        margin: 0;
        max-width: 100%
    }
}

.modal-drawer .modal-content,
.modal-drawer .modal-footer,
.modal-drawer .modal-header {
    border-radius: 0
}

.modal-drawer .modal-content {
    height: 100%
}

.modal-drawer .modal-body {
    overflow: auto
}

.modal-drawer-right {
    right: 0;
    box-shadow: -1px 0 3px 0 rgba(20, 20, 31, .15)
}

.modal.fade .modal-drawer-right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.modal-drawer-right .modal-content {
    border-width: 0 0 0 1px
}

.modal-drawer-left {
    left: 0;
    box-shadow: 1px 0 3px 0 rgba(20, 20, 31, .15)
}

.modal.fade .modal-drawer-left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.modal-drawer-left .modal-content {
    border-width: 0 1px 0 0
}

.modal.show .modal-drawer-left,
.modal.show .modal-drawer-right {
    -webkit-transform: translate(0);
    transform: translate(0)
}

.modal-docked .modal-dialog {
    margin: 0;
    position: fixed;
    bottom: 6.125rem;
    right: 1.25rem;
    width: 100%;
    height: calc(100vh - 7.125rem)
}

.modal-docked.fade .modal-dialog {
    -webkit-transform: translateY(1.25rem);
    transform: translateY(1.25rem)
}

.modal-docked.show .modal-dialog {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.modal-docked .modal-content {
    position: absolute;
    bottom: 0;
    max-height: 100%;
    border: 0;
    box-shadow: 0 5px 40px rgba(20, 20, 31, .15)
}

.modal-docked .modal-body {
    overflow-y: auto
}

.modal-docked .close {
    margin: 0;
    padding: 0;
    position: absolute;
    right: .125rem;
    bottom: -4.6125rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    opacity: 1
}

.modal-docked .close:focus:not(.disabled),
.modal-docked .close:hover:not(.disabled) {
    opacity: 1
}

.modal-dialog-overflow .modal-header,
.modal-drawer .modal-header {
    box-shadow: 0 2px 0 0 rgba(20, 20, 31, .125)
}

.modal-dialog-overflow .modal-footer,
.modal-drawer .modal-footer {
    box-shadow: 0 -2px 0 0 rgba(20, 20, 31, .125)
}

.modal-dialog-overflow .modal-body-scrolled,
.modal-drawer .modal-body-scrolled {
    box-shadow: none
}

//mat slider
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: #0075ff !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: white;
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
}

// mat calender
.mat-card:not([class*=mat-elevation-z]) {
    box-shadow: none;
}

.mat-calendar-body-selected {
    background-color: #008BFF;
    color: #fff;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: #91ccfc !important;
}

.mat-calendar-body-label {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.mat-calendar-body-today.mat-calendar-body-selected {
    background-color: #008BFF !important;
}

.mat-card {
    padding: 0 16px !important;
}

.mat-calendar-controls {
    margin-bottom: 20px !important;
    margin-top: 0 !important;
}
.mat-calendar-body-label {
    color: white !important;
}

.mat-calendar-body-today.mat-calendar-body-selected {
    border-radius: 10px !important;
    box-shadow: none !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-radius: 10px !important;
}

.mat-calendar-body-selected {
    border-radius: 10px !important;
}

.mat-calendar-table-header-divider::after {
    content: none !important;
    position: none !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border-color: #008BFF;
}

.mat-calendar-body-cell-content.mat-focus-indicator {
    font-weight: 500 !important;
}

.mat-calendar-previous-button {
    position: relative !important;
    right: 58%;
    border: 1px solid lightgray !important;
    border-radius: 10px !important;
}

.mat-calendar-next-button {
    position: relative !important;
    right: 15%;
    border: 1px solid lightgray !important;
    border-radius: 10px !important;
}

.mat-calendar-period-button {
    min-width: 0;
    position: relative !important;
    left: 36%;
}

.mat-date-range-input-separator :hover{
    background-color: #041829 !important;
}

.form-check-input{
    width: 1em;
    -webkit-appearance: auto !important;
}

.ng-select div, .ng-select input, .ng-select span {
    box-sizing: border-box;
    white-space: normal;
}

@media (max-width:767px) {
    .mat-calendar-next-button {
        right: 10%;
    }

    .mat-calendar-period-button {
        left: 30%;
    }
}

// mat calender

@media (min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-md-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-lg-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:1200px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-xl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width:576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-sm-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

.schedule {

    // .volume-container {
    //     display: none !important;
    // }
    .controls svg:nth-child(2) {
        position: fixed !important;
        top: 50% !important;
        left: 36%;
    }

    .controls svg:nth-child(4) {
        position: fixed !important;
        top: 50% !important;
        right: 28%;
    }

    // .controls svg:nth-child(3) {
    //     position: relative;
    //     left: 50%;
    //     bottom: 59px;
    // }

    // .controls svg:nth-child(4) {
    //     display: none !important;
    // }

    // .wrapper {
    //     width: auto !important;
    // }

    // .controls svg:nth-child(3)::before {
    //     content: '';
    // }

    // .controls svg:nth-child(3) {
    //     width: 0px;
    //     display: block;
    //     height: 0px;
    //     border: black 2px solid;
    //     background-size: cover;
    //     color: black;
    //     text-decoration: none;
    //     padding: 5px 0px 5px 0px;
    //     border-radius: 50%;
    // }

}

.app-aside {
    // position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    width: calc(100vw - 3.5rem);
    max-width: 19rem;
    z-index: 1050;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out
}

.app-aside.show .aside-header>.hamburger {
    display: block
}

.app-aside.show {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.app-aside+.app-main {
    padding-left: 0
}

.app-aside .top-bar-brand {
    width: auto
}

.aside-backdrop {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(20, 20, 31, .8);
    z-index: 1040
}

.aside-backdrop.show {
    display: block;
    -webkit-animation: fadeInBackdrop .26s ease forwards;
    animation: fadeInBackdrop .26s ease forwards
}

.aside-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%
}

.aside-header {
    position: relative;
    display: block;
    box-shadow: 0 0 0 1px rgba(20, 20, 31, .05), 0 1px 3px 0 rgba(20, 20, 31, .15);
    z-index: 3
}

.aside-header>.hamburger {
    display: none;
    position: absolute;
    top: .75rem;
    right: -3rem
}

.aside-header .btn-account {
    padding: 1rem;
    width: 100%;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.aside-header .btn-account.focus,
.aside-header .btn-account:focus {
    background-color: inherit
}

.aside-header .btn-account:active {
    color: #363642;
    background-color: rgba(52, 108, 176, .08)
}

.aside-header .btn-account[aria-expanded=true]:before {
    content: "";
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2
}

.aside-header .btn-account .account-name {
    font-size: 1.125rem;
    line-height: 1.5rem
}

.aside-header .btn-account .account-description {
    font-size: .875em
}

.dropdown-aside {
    position: absolute;
    width: 100%;
    box-shadow: 0 0 0 1px rgba(20, 20, 31, .05), 0 1px 3px 0 rgba(20, 20, 31, .15);
    z-index: 1
}

.dropdown-aside.show {
    max-height: calc(100vh - 4.5rem);
    overflow: auto
}

.dropdown-aside .dropdown-icon {
    text-align: center;
    width: 1.5rem;
    margin-right: .5em
}

.aside-menu {
    position: relative;
    -ms-flex: 1;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

.app-aside-expand-sm {
    top: auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 1030
}

@media (max-width:575.98px) {
    .app-aside-expand-sm {
        top: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        z-index: 1050;
        border-right: 0
    }

    .app-aside-expand-sm.show {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@media (min-width:576px) {
    .app-aside-expand-sm {
        top: 3.5rem;
        width: 19rem;
        z-index: 5
    }
}

@media (min-width:576px) {
    .app-aside-expand-sm+.app-main {
        padding-left: 19rem
    }
}

@media (max-width:575.98px) {
    .app-aside-expand-sm~.aside-backdrop {
        display: none
    }

    .app-aside-expand-sm~.aside-backdrop.show {
        display: block;
        -webkit-animation: fadeInBackdrop .26s ease forwards;
        animation: fadeInBackdrop .26s ease forwards
    }
}

@media (min-width:576px) {
    .app-aside-expand-sm~.aside-backdrop.show {
        display: none
    }
}

.app-aside-expand-md {
    top: auto;
    position: unset !important;
    width: 100% !important;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 1030
}

@media (max-width:767.98px) {
    .app-aside-expand-md {
        top: 0;
        position: absolute !important;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 8%, 0);
        z-index: 1050;
        border-right: 0
    }

    .app-aside-expand-md.show {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@media (min-width:768px) {
    .app-aside-expand-md {
        top: 3.5rem;
        width: 19rem;
        z-index: 5
    }
}

// @media (min-width:768px) {
//     .app-main {
//         padding-left: 0rem
//     }
// }

@media (max-width:767.98px) {
    .app-aside-expand-md~.aside-backdrop {
        display: none
    }

    .app-aside-expand-md~.aside-backdrop.show {
        display: block;
        -webkit-animation: fadeInBackdrop .26s ease forwards;
        animation: fadeInBackdrop .26s ease forwards
    }
}

@media (min-width:768px) {
    .app-aside-expand-md~.aside-backdrop.show {
        display: none
    }
}

.app-aside-expand-lg {
    top: auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 1030
}

@media (max-width:991.98px) {
    .app-aside-expand-lg {
        top: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        z-index: 1050;
        border-right: 0
    }

    .app-aside-expand-lg.show {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@media (min-width:992px) {
    .app-aside-expand-lg {
        top: 3.5rem;
        width: 19rem;
        z-index: 5
    }
}

@media (min-width:992px) {
    .app-aside-expand-lg+.app-main {
        padding-left: 19rem
    }
}

@media (max-width:991.98px) {
    .app-aside-expand-lg~.aside-backdrop {
        display: none
    }

    .app-aside-expand-lg~.aside-backdrop.show {
        display: block;
        -webkit-animation: fadeInBackdrop .26s ease forwards;
        animation: fadeInBackdrop .26s ease forwards
    }
}

@media (min-width:992px) {
    .app-aside-expand-lg~.aside-backdrop.show {
        display: none
    }
}

.app-aside-expand-xl {
    top: auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 1030
}

@media (max-width:1199.98px) {
    .app-aside-expand-xl {
        top: 0;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        z-index: 1050;
        border-right: 0
    }

    .app-aside-expand-xl.show {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@media (min-width:1200px) {
    .app-aside-expand-xl {
        top: 3.5rem;
        width: 19rem;
        z-index: 5
    }
}

@media (min-width:1200px) {
    .app-aside-expand-xl+.app-main {
        padding-left: 19rem
    }
}

@media (max-width:1199.98px) {
    .app-aside-expand-xl~.aside-backdrop {
        display: none
    }

    .app-aside-expand-xl~.aside-backdrop.show {
        display: block;
        -webkit-animation: fadeInBackdrop .26s ease forwards;
        animation: fadeInBackdrop .26s ease forwards
    }
}

@media (min-width:1200px) {
    .app-aside-expand-xl~.aside-backdrop.show {
        display: none
    }
}

.app-aside-expand {
    top: auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 1030;
    top: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    z-index: 1050;
    border-right: 0;
    top: 3.5rem;
    width: 19rem;
    z-index: 5
}

.app-aside-expand.show {
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
}

.app-aside-expand+.app-main {
    padding-left: 19rem
}

.app-aside-expand~.aside-backdrop {
    display: none
}

.app-aside-expand~.aside-backdrop.show {
    display: block;
    -webkit-animation: fadeInBackdrop .26s ease forwards;
    animation: fadeInBackdrop .26s ease forwards;
    display: none
}

.app-aside-light {
    color: inherit;
    background-color: #fff
}

.app-aside-light .aside-header,
.app-aside-light .aside-header .btn-account[aria-expanded=true]:before,
.app-aside-light .dropdown-aside {
    background-color: #fff
}

.top-bar-item,
.top-bar-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.top-bar-item {
    padding: 0
}

@media (min-width:768px) {
    .top-bar-item {
        padding-right: 1rem;
        padding-left: 1rem
    }
}

@media (min-width:992px) {
    .top-bar-item {
        padding-left: 2rem
    }
}

.top-bar-item-full {
    -ms-flex: 1;
    flex: 1
}

.top-bar-item-right {
    margin-left: auto
}

.hamburger {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: .5rem;
    cursor: pointer;
    height: 2.25rem;
    width: 2.25rem;
    transition: background-color .3s linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    border-radius: .25rem;
    margin: 0;
    overflow: visible;
    outline: 0
}

.hamburger-sm .hamburger-inner {
    -webkit-transform: scale(.8);
    transform: scale(.8)
}

.hamburger.focus,
.hamburger:focus,
.hamburger:hover {
    background-color: hsla(0, 0%, 100%, .05);
    outline: 0
}

.hamburger:active {
    background-color: rgba(20, 20, 31, .05)
}

.hamburger-box {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    position: relative
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -1px
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
    width: 1.25rem;
    height: 2px;
    background-color: #fff;
    border-radius: 2px;
    position: absolute;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    transition-duration: .15s;
    transition-timing-function: ease
}

.hamburger-inner:after,
.hamburger-inner:before {
    content: "";
    display: block
}

.hamburger-inner:before {
    top: -6px
}

.hamburger-inner:after {
    bottom: -6px
}

.hamburger-light .hamburger-inner,
.hamburger-light .hamburger-inner:after,
.hamburger-light .hamburger-inner:before {
    background-color: #222230
}

.hamburger-arrow.active .hamburger-inner:before {
    -webkit-transform: translate3d(-.25rem, 1px, 0) rotate(-45deg) scaleX(.7);
    transform: translate3d(-.25rem, 1px, 0) rotate(-45deg) scaleX(.7)
}

.hamburger-arrow.active .hamburger-inner:after {
    -webkit-transform: translate3d(-.25rem, -1px, 0) rotate(45deg) scaleX(.7);
    transform: translate3d(-.25rem, -1px, 0) rotate(45deg) scaleX(.7)
}

.hamburger-squeeze .hamburger-inner {
    transition-duration: 75ms;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19)
}

.hamburger-squeeze .hamburger-inner:before {
    transition: top 75ms ease .12s, opacity 75ms ease
}

.hamburger-squeeze .hamburger-inner:after {
    transition: bottom 75ms ease .12s, -webkit-transform 75ms cubic-bezier(.55, .055, .675, .19);
    transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55, .055, .675, .19);
    transition: bottom 75ms ease .12s, transform 75ms cubic-bezier(.55, .055, .675, .19), -webkit-transform 75ms cubic-bezier(.55, .055, .675, .19)
}

.hamburger-squeeze.active .hamburger-inner {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1)
}

.hamburger-squeeze.active .hamburger-inner:before {
    top: 0;
    opacity: 0;
    transition: top 75ms ease, opacity 75ms ease .12s
}

.hamburger-squeeze.active .hamburger-inner:after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: bottom 75ms ease, -webkit-transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
    transition: bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s;
    transition: bottom 75ms ease, transform 75ms cubic-bezier(.215, .61, .355, 1) .12s, -webkit-transform 75ms cubic-bezier(.215, .61, .355, 1) .12s
}

.example-list {
    border: none !important;
}

.schedule {
    .wrapper {
        align-items: center;
        background: #000;
        border-radius: 15px;
        display: inline-block !important;
        font-family: Raleway, sans-serif;
        height: 130px;
        width: 500px;
    }

    .wrapper {
        .error {
            color: #ff3737;
            text-transform: capitalize !important;
            position: absolute !important;
            left: 41%;
            top: 161%;
        }

        .cover img {
            border-radius: unset !important;
            margin-left: 0 !important;
            max-height: 100% !important;
            max-width: 100% !important;
            height: 416px;
            width: 467px;
            padding: 0 0px 0 28px !important;
        }
    }

    .slider {
        margin-top: 10px !important;
    }
}

.voiceMail {
    .volume-container {
        display: none !important;
    }

    .controls svg:nth-child(1) {
        display: none !important;
    }

    .controls svg:nth-child(2) {
        display: none !important;
    }

    .controls svg:nth-child(3) {
        position: relative;
        left: 58%;
        bottom: 59px;
    }

    .controls svg:nth-child(4) {
        display: none !important;
    }

    .controls svg:nth-child(3)::before {
        content: '';
    }

    .controls svg:nth-child(3) {
        width: 0px;
        display: block;
        height: 0px;
        background: #0984f0;
        background-size: cover;
        color: white;
        text-decoration: none;
        padding: 8px 0px 8px 0px;
        border-radius: 50%;
    }

    .slidecontainer {
        width: 88% !important;
        margin-left: 36px;

        .slider {
            height: 4px !important;
            -webkit-appearance: progress-bar !important;
        }

        input[type="range"]::-webkit-slider-thumb {
            background-color: white !important;
            border: #0984f0 solid;
        }

    }

    .time-line:nth-child(1) {
        display: none !important;
    }
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: .875rem;
    background-color: hsla(0, 0%, 100%, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .25rem;
    box-shadow: 0 .25rem .75rem rgba(20, 20, 31, .1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    // opacity: 0;
    // position:absolute;
    // top:0px;
    // right: 0px;
}

.toast:not(:last-child) {
    margin-bottom: .75rem
}

.toast.showing {
    opacity: 1
}

.toast.show {
    display: block;
    opacity: 1
}

.toast.hide {
    display: none
}

.toast-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .25rem .75rem;
    color: #888c9b;
    background-color: hsla(0, 0%, 100%, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.toast-body {
    padding: .75rem
}

#toast-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 1rem;
    right: 0;
    left: 0;
    font-size: .875rem
}

#toast-container.toast-top-full-width>div:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

#toast-container.toast-bottom-full-width>div:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

#toast-container.toast-top-center,
#toast-container.toast-top-left,
#toast-container.toast-top-right {
    top: 3.5rem
}

#toast-container.toast-top-full-width {
    top: 3.5rem
}

/* .toast-title {
    margin-top: -7px !important;
    height: 0px;
    margin-left: -36px;
} */
/* .toast-message {
    margin-left: -26px !important;
    margin-top: 15px !important;
} */
/* .toast-top-right{
    align-items:center !important;
} */
.toast {
    box-shadow: none !important;
}

#toast-container.toast-bottom-center,
#toast-container.toast-bottom-left,
#toast-container.toast-bottom-right {
    bottom: 1rem
}

#toast-container.toast-bottom-full-width {
    bottom: 0
}

#toast-container.toast-bottom-left,
#toast-container.toast-top-left {
    -ms-flex-align: start;
    align-items: flex-start
}

#toast-container.toast-bottom-right,
#toast-container.toast-top-right {
    -ms-flex-align: end;
    align-items: flex-end
}

/* #toast-container>div {
    display: -ms-flexbox;
    display: flex;
    padding: 1rem 3.25rem 1rem 1rem;
    margin: 0!important;
    width: auto!important;
    min-width: 288px;
    max-width: 568px;
    opacity: 1
} */

#toast-container>div,
#toast-container>div:hover {
    box-shadow: 0 2px 5px 0 rgba(20, 20, 31, .2)
}

#toast-container>div+div {
    margin-top: .5rem !important
}

/* body #toast-container>.toast-error,
body #toast-container>.toast-info,
body #toast-container>.toast-success,
body #toast-container>.toast-warning {
    background-image: none!important
} */

.toast-close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    margin-left: .5em;
    color: inherit;
    float: none;
    opacity: .4
}

.toast-close-button:focus,
.toast-close-button:hover {
    color: #000;
    opacity: .65
}

#toast-container>.toast-success,
.toast-success {
    background-color: #DAFBE4;
    color: #000;
    border-left: 5px solid #04a204;
}

#toast-container>.toast-error,
.toast-error {
    background-color: #FFF0F1;
    color: #000;
    border-left: 5px solid red;
}

#toast-container>.toast-info,
.toast-info {
    background-color: #222230;
    color: #000
}

#toast-container>.toast-warning,
.toast-warning {
    background-color: #FFF8E1;
    color: #000;
    border-left: 5px solid #f7c46c;
}

.toast-progress {
    opacity: .15
}

#toast-container>.toast-error.custom-icon {
    background-image: url('../src/assets/images/Error.png') !important;
}

#toast-container>.toast-success.custom-icon {
    background-image: url('../src/assets/images/Success.png') !important;
}

#toast-container>.toast-warning.custom-icon {
    background-image: url('../src/assets/images/Warning.png') !important;
}

@media (max-width:575.98px) {
    #toast-container {
        padding: 0
    }

    #toast-container.toast-bottom-center,
    #toast-container.toast-bottom-left,
    #toast-container.toast-bottom-right,
    #toast-container.toast-top-center,
    #toast-container.toast-top-left,
    #toast-container.toast-top-right {
        left: 0;
        right: 0
    }

    #toast-container.toast-bottom-center>div,
    #toast-container.toast-bottom-full-width>div,
    #toast-container.toast-bottom-left>div,
    #toast-container.toast-bottom-right>div,
    #toast-container.toast-top-center>div,
    #toast-container.toast-top-full-width>div,
    #toast-container.toast-top-left>div,
    #toast-container.toast-top-right>div {
        width: 100% !important;
        max-width: 100%;

    }

    #toast-container.toast-top-center,
    #toast-container.toast-top-full-width,
    #toast-container.toast-top-left,
    #toast-container.toast-top-right {
        top: calc(3.5rem - 1px)
    }

    #toast-container.toast-bottom-center,
    #toast-container.toast-bottom-left,
    #toast-container.toast-bottom-right {
        bottom: 0
    }

    #toast-container>div {
        padding: 1rem 3.25rem 1rem 1rem
    }

    #toast-container>div.rtl {
        padding: 1rem 1rem 1rem 3.25rem
    }

    #toast-container>div {
        border-radius: 0;
        margin-bottom: 0
    }

    #toast-container .toast-close-button {
        top: 1rem;
        right: 1rem
    }

    #toast-container .rtl .toast-close-button {
        right: auto;
        left: 1rem
    }
}

.toast-top-center>div,
.toast-top-full-width>div {
    -webkit-animation: toastTop .3s;
    animation: toastTop .3s
}

.toast-top-right>div {
    -webkit-animation: toastRight .3s;
    animation: toastTop .3s
}

.toast-top-left>div {
    -webkit-animation: toastLeft .3s;
    animation: toastTop .3s
}

.toast-close-button {
    position: fixed !important;
    opacity: unset !important;
    right: 1rem !important;
    top: 1.5rem !important;
    color: #000 !important;
}

.toast-close-button:focus,
.toast-close-button:hover {
    color: #000 !important;
    text-decoration: none;
    cursor: pointer;
    opacity: unset !important;
}


@media (max-width:575.98px) {

    .toast-top-left>div,
    .toast-top-right>div {
        -webkit-animation: toastTop .3s;
        animation: toastTop .3s
    }
}

.toast-bottom-center>div,
.toast-bottom-full-width>div,
.toast-bottom-left>div,
.toast-bottom-right>div {
    -webkit-animation: toastBottom .3s;
    animation: toastBottom .3s
}

@-webkit-keyframes toastBottom {
    0% {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@keyframes toastBottom {
    0% {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@-webkit-keyframes toastTop {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@keyframes toastTop {
    0% {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@-webkit-keyframes toastRight {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@keyframes toastRight {
    0% {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@-webkit-keyframes toastLeft {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@keyframes toastLeft {
    0% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

#toast-container>div:hover {
    box-shadow: unset !important;
    opacity: 0.8 !important;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    cursor: pointer;
}

#toast-container>div,
#toast-container>div:hover {
    /* box-shadow: none !important; */
    border-radius: 0px !important;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 0px;
}

#toast-container>div {
    padding: 15px !important;
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 13px;
    /* background-color: hsla(0, 0%, 100%, .85); */
    /* background-clip: padding-box; */
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .25rem;
    /* box-shadow: 0 0.25rem 0.75rem rgba(20, 20, 31, .1); */
    -webkit-backdrop-filter: blur(10px);
    /* backdrop-filter: blur(10px); */
    opacity: 0;
}

body #toast-container>.toast-error,
body #toast-container>.toast-info,
body #toast-container>.toast-success,
body #toast-container>.toast-warning {
    min-width: 370px;
    padding-left: 50px !important;
}

/* .toast.toast-error>.toast-message{
    word-wrap: break-word;
    margin-top: 15px !important;
    margin-left: -34px !important;
} */
/* .toast.toast-success>.toast-message{
    word-wrap: break-word;
    margin-top: 10px;
    margin-left: -48px;
} */
.custom-select-ellipsis {
    .ng-select.ng-select-single .ng-select-container{
        height: auto;
    }
}